import React, { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';


if (typeof window !== 'undefined') {
  window.axios = axios;
}

const interceptor = async (req) => {
  const isBrowser = typeof window !== 'undefined';
  const isIframe = isBrowser && window.location !== window.parent.location;


  if (isBrowser) {
    const url = req.baseURL || req.url;

    if (url && /^(https)/gm.test(url) && !url.includes(window.location.origin)) {
      return {
        headers: {
          ..._.omit(req.headers, ['Authorization', 'authorization', 'Cookie']),
          common: {
            ..._.omit(req.headers, ['Authorization', 'authorization', 'Cookie']),
            origin: window.location.origin,
          },
          origin: window.location.origin,
        },
        data: req.data instanceof File ? req.data : _.omit(req.data, ['isIframe']),
        params: _.omit(req.params, ['isIframe']),
      };
    }

    // if running in an iframe, include isIframe in all HTTP methods either as payload body or query string
    if (isIframe) {
      req.headers['x-isiframe'] = true;
      req.headers.common = req.headers.common || {};
      req.headers.common['x-isiframe'] = true;
    }

    // this is for upload to GCS signed url. remove any unnecessary headers, payload body, and params
    if (req.baseURL && !req.baseURL.includes(window.location.origin)) {
      return {
        headers: {
          ..._.omit(req.headers, ['Authorization', 'authorization', 'Cookie']),
          origin: window.location.origin,
        },
        data: _.omit(req.data, ['isIframe']),
        params: _.omit(req.params, ['isIframe']),
      };
    }
  }


  return req;
};

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params);
};


export const initApi = () => {
  if (axios.interceptors.request.handlers.length > 0) {
    axios.interceptors.request.handlers = [];
  }

  axios.interceptors.request.use(interceptor);

  axios.defaults.paramsSerializer = (params) => qs.stringify(params);

  const _api = {
    instance: axios.create(),
    CancelToken: axios.CancelToken,
    isCancel: axios.isCancel,
    get: axios.get,
    delete: axios.delete,
    patch: axios.patch,
    post: axios.post,
    put: axios.put,
    getOrgLevelResourceMap: () => {
      return axios.get('/api/role-permissions').then(({ data }) => data);
    },

    enterNotaryRoom: (nsId) => axios({
      method: 'POST',
      url: `/api/internal/notarization/${nsId}/enter-room`,
    }),
    uploadFile: (url, file, options) => {
      const inst = axios.create();

      inst.interceptors.request.handlers = [];

      return inst.put(url, file, {
        withCredentials: false,
        headers: { 'Content-Type': 'application/pdf' },
        ...options,
      });
    },
    getEnotePdf: (enoteId) => axios({
      url: `/api/integration/eoriginal/${enoteId}/document`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/pdf' },
    }),
    uploadXmlFile: (data, options) => {
      try {
        return axios({
          method: 'POST',
          url: '/api/integration/eoriginal',
          data,
          ...options,
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    validateXmlFile: (data) => {
      try {
        return axios({
          method: 'POST',
          url: '/api/integration/eoriginal/validate',
          data,
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    updateEnote: (enoteId, data) => {
      try {
        return axios({
          method: 'PATCH',
          url: `/api/integration/eoriginal/${enoteId}`,
          data,
        });
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    refreshAccessToken: async (token) => {
      const { data } = await axios.post('/api/token', { refreshToken: token });

      localStorage.setItem('accessToken', data.token);
      localStorage.setItem('refreshToken', data.refreshToken);

      return data;
    },
    verifyEmail: async (emailVerificationToken) => {
      const { data } = await axios({
        method: 'PATCH',
        url: '/api/verify-email',
        data: { token: emailVerificationToken },
      });

      return data;
    },
    loadDashboard: async () => {
      const { data } = await axios.get('/api/organization/dashboard');

      return data;
    },

    getDocumentBuffer: (nsId, docId, preview = true) => axios({
      method: 'GET',
      url: `/api/internal/notarization/${nsId}/document?type=buffer${docId ? `&docId=${docId}` : ''}&includeAnnots=${(preview ? '1' : '0')}`,
      responseType: 'arraybuffer',
    }),
    downloadArchiveFiles: (nsId, opts) => axios({
      method: 'GET',
      url: `/api/archives/${nsId}/download`,
      responseType: 'arraybuffer',
      ...opts,
    }),
    searchNotaries: async (params) => {
      const { data } = await axios({
        method: 'GET',
        params,
        url: '/api/internal/notary/search',
      });

      return data;
    },
    getNotaryExists: async (notaryId) => {
      const { data } = await axios({
        method: 'GET',
        url: `/api/internal/notary/${notaryId}/exists`,
      });

      return data;
    },

    getNotaries: async (params = { accountActive: true }) => {
      const { data: { notaries } } = await axios.get('/api/organization/notaries', { params });

      return notaries;
    },
    getNsParticipants: async (nsId) => {
      const { data } = await axios({
        url: `/api/internal/notarization/${nsId}/participants`,
        method: 'GET',
      });

      return data;
    },
    getDocuments: async (nsId, params = {}) => {
      const { data } = await axios({
        url: `/api/notarization/${nsId}/documents`,
        method: 'GET',
        params,
      });

      return data;
    },
    getNsDocuments: async (nsId) => {
      const { data } = await axios({
        url: `/api/internal/notarization/${nsId}/documents`,
        method: 'GET',
      });

      return data;
    },
    resetPassword: async (id) => {
      const { data } = await axios.post(`/api/users/${id}/reset-password`);

      return data;
    },

    updateNotary: async ({ id, notary }) => {
      const { data } = await axios.patch(`/api/organization/notary/${id}`, {
        notary,
      });

      return data;
    },
    getPermissions: async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/api/organization/permissions',
      });

      return data;
    },
    updateRolePermissions: async (payload) => {
      const { data } = await axios.patch(
        '/api/organization/permissions',
        payload
      );

      return data;
    },
    getRoles: async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/api/organization/roles',
      });

      return data;
    },
    updateUserRole: async (userId, roleId) => {
      const { data } = await axios({
        method: 'PATCH',
        url: `/api/organization/users/${userId}/role`,
        data: { roleId },
      });

      return data;
    },
    getRequests: async (params) => {
      const {
        data,
      } = await axios.get('/api/request', { params });

      return data;
    },
    getRequestById: async (requestId) => {
      const { data: { data } } = await axios.get(`/api/request/${requestId}`, {});

      return data;
    },
    lockRequest: async (requestId, assignTo, assignToOrg) => {
      const { data } = await axios.patch(`/api/request/${requestId}/reassign`, {
        assignTo,
        assignToOrg,
      });

      return data;
    },
    getNotarySessions: async (page = 1, limit = 20, params = {}) => {
      const { data } = await axios.get('/api/notary-session', {
        params: {
          ...params,
          page,
          limit,
        },
      });

      return data;
    },
    getNotaryById: async (id) => {
      const {
        data: { user, base64Images },
      } = await axios.get(`/api/organization/notary/${id}`);

      user.notary.images = base64Images;

      return user;
    },
    getNotary: async (notaryId) => {
      const { data } = await axios({
        method: 'GET',
        url: `/api/internal/notary/${notaryId}`,
      });

      return data;
    },

    getNotaryUser: async (notaryId) => {
      const { data } = await axios({
        method: 'GET',
        url: `/api/internal/notary/${notaryId}/user`,
      });

      return data;
    },

    getUser: async () => {
      const { data } = await axios.get('/api/user');

      return data;
    },
    getUsers: async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/api/organization/permissions/users',
      });

      return data;
    },
    updateUser: async (userId, values) => {
      const { data } = await axios({
        method: 'PATCH',
        url: '/api/organization/permissions/user',
        data: {
          id: userId,
          ...values,
        },
      });

      return data;
    },
    getOrgMersDefault: async () => {
      const { data } = await axios({
        method: 'GET',
        url: '/api/organizationSettings/mersRegistrationDefault',
      });

      return data;
    },
    getUserResources: async () => {
      const { data: { resources } } = await axios.get('/api/user/resources');

      return resources;
    },

    login2fa: async (email, password, recaptchaToken) => {
      try {
        const { data } = await axios.post('/api/login', { email, password, recaptchaToken });

        return data;
      } catch (err) {
        if (err.response && err.response.status === 302) {
          console.log('err', err.response);

          return err.response.data;
        }

        if (err.response && err.response.status >= 400) {
          throw err;
        }
      }
    },
    login: async (authCode, token) => {
      const { data } = await axios.post('/api/second-factor', {
        code: authCode,
        token,
      });

      return data;
    },
    // sets values on localStorage
    set2FaSession: (token) => {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    },

    // sets values on localStorage
    setSession: (accessToken, refreshToken) => {
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        delete axios.defaults.headers.common.Authorization;
      }

      if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
      }
    },

    getDocumentsPageData: (query, opts = {}) => {
      return axios.get(`/api/documents-page?${query}`, {
        ...opts,
      })
        .catch((err) => {
          if (axios.isCancel(err)) {
            console.debug('Request canceled', err.message);
          } else {
            throw err;
          }
        })
        .then((res) => _.get(res, 'data', null));
    },
    getNotarySessionUsers: (nsId) => {
      return axios.get(`/api/documents-page/${nsId}/notary-session-users`)
        .then((resp) => resp.data);
    },
    resendNsInvite: (reqId, nsId, nsUserId) => {
      return axios.post(`/api/request/${reqId}/notary-session/${nsId}/notification`, {
        type: 'request_invite',
        participants: [nsUserId],
      });
    },
    updateNsUserEmail: async (nsId, nsUserId, newEmail) => {
      const { data } = await axios.post(`/api/notarization/${nsId}/notary-session-user/${nsUserId}/updateEmail`, { email: newEmail });

      return data;
    },
    getNotarySessionDocs: async (nsId) => {
      const { data } = await axios.get(`/api/documents-page/${nsId}/documents`);

      return data;
    },
    getNotarySessionAdditionalInfo: async (nsId) => {
      const { data } = await axios.get(`/api/documents-page/${nsId}/additional-info`);

      return data;
    },

    // Templating
    getOrganizationTemplateRoles: async () => {
      const { data } = await axios.get('/api/integration/templating/template-roles');

      return data;
    },
    getIframeUrl: async (params) => {
      const { data } = await axios.post('/api/integration/templating/iframe-url', params);

      return data;
    },
  };

  const api = {
    ..._api,
    api: _api,
  };

  return api;
};


const ApiContext = createContext();

export const useApi = () => {
  const client = useContext(ApiContext);

  if (!client) {
    throw new Error('useApi must be used within ApiProvider');
  }

  return client;
};

export const withApi = (Component) => (props) => {
  const api = useApi();

  return (
    <Component {...props} api={api} />
  );
};

export function ApiProvider({ client = {}, children }) {
  const initClient = useCallback(() => ({ ...client, ...initApi() }), [client]);

  const api = initClient();

  return (
    <ApiContext.Provider
      value={api}
    >
      {children}
    </ApiContext.Provider>
  );
}

ApiProvider.defaultProps = {
  client: axios,
};

ApiProvider.propTypes = {
  client: PropTypes.func,
  children: PropTypes.node.isRequired,
};
